import { StateCreator } from "zustand";

type Plan = {
  Id: number;
  Title: string;
  Price: number;
  Type: string;
  Amount: number;
};

type PlanSlice = {
  plan: Plan;
  setPlan: (plan: Plan) => void;
};

const initialState = {
  Id: 1,
  Title: "",
  Price: 0,
  Type: "",
  Amount: 1,
};

const createPlanSlice: StateCreator<PlanSlice> = (set) => ({
  plan: initialState,
  setPlan: (data) => set((state) => ({ plan: { ...state.plan, ...data } })),
});

export default createPlanSlice;
export type { Plan, PlanSlice };
