import { StateCreator } from "zustand";

type Payment = {
  cardname: string;
  expire: string;
  cardnumber: string;
  securitycode: string;
  coupon: string;
  couponId: number;
  couponDiscountPerc: number;
  couponRefCode: string;
  total: number;
  subtotal: number;
  refCode: string;
  parentRefCode: string;
  customerRefCode: string;
};

type PaymentSlice = {
  payment: Payment;
  setPayment: (payment: Payment) => void;
};

const initialState = {
  cardname: "",
  expire: "",
  cardnumber: "",
  securitycode: "",
  coupon: "",
  couponId: 0,
  couponDiscountPerc: 0,
  couponRefCode: "",
  total: 0,
  subtotal: 0,
  refCode: "",
  parentRefCode: "",
  customerRefCode: "",
};

const createPaymentSlice: StateCreator<PaymentSlice> = (set) => ({
  payment: initialState,
  setPayment: (data) =>
    set((state) => ({ payment: { ...state.payment, ...data } })),
});

export default createPaymentSlice;
export type { Payment, PaymentSlice };
