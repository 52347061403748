import { StateCreator } from "zustand";

type Product = {
  Id: number;
  Title: string;
  Price: number;
  Type: string;
  Amount: number;
  Duration: number;
  pricingRefCode: string;
  gifted_username: string;
  gift_message: string;
  gifted_user_id: string;
  gifted_usermail: string;
};

type ProductSlice = {
  product: Product;
  setProduct: (product: Product) => void;
};

const initialState = {
  Id: 0,
  Title: "",
  Price: 0,
  Type: "",
  Amount: 1,
  Duration: 0,
  pricingRefCode: "",
  gifted_username: "",
  gift_message: "",
  gifted_user_id: "",
  gifted_usermail: "",
};

const createProductSlice: StateCreator<ProductSlice> = (set) => ({
  product: initialState,
  setProduct: (data) =>
    set((state) => ({ product: { ...state.product, ...data } })),
});

export default createProductSlice;
export type { Product, ProductSlice };
