import { StateCreator } from "zustand";

type ChessboardTheme = {
  board: string;
  pieces: string;
  playSounds: boolean;
  sounds: string;
  highlight: boolean;
  coordinates: boolean;
};

type ChessboardThemeSlice = {
  chessboardTheme: ChessboardTheme;
  setChessboardTheme: (chessboardTheme: ChessboardTheme) => void;
};

const initialState = {
  board: "default-board",
  pieces: "default-pieces",
  playSounds: true,
  sounds: "robot",
  highlight: true,
  coordinates: true,
};

const createChessboardThemeSlice: StateCreator<ChessboardThemeSlice> = (
  set,
) => ({
  chessboardTheme: initialState,
  setChessboardTheme: (data) =>
    set((state) => ({
      chessboardTheme: { ...state.chessboardTheme, ...data },
    })),
});

export default createChessboardThemeSlice;
export type { ChessboardTheme, ChessboardThemeSlice };
