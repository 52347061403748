import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium select-none ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-white hover:bg-primary-hover focus:bg-primary focus:ring focus:ring-primary-focus active:bg-primary-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        secondary:
          "bg-primary-bgcolor text-primary hover:text-primary-hover focus:text-primary focus:ring focus:ring-primary-focus active:text-primary-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "secondary-outline":
          "bg-primary-bgcolor text-primary hover:text-primary-hover focus:text-primary focus:ring focus:ring-primary-focus active:text-primary-pressed disabled:bg-neutral-100 disabled:text-neutral-400 border border-primary-border hover:bg-primary-focus",
        white:
          "bg-white text-black hover:text-primary-hover focus:text-primary focus:ring focus:ring-primary-focus active:text-primary-pressed disabled:bg-neutral-100 disabled:text-neutral-400 shadow",
        outline:
          "bg-primary-bgcolor text-primary border border-primary-border hover:text-primary-hover hover:border-primary-hover focus:text-primary focus:ring focus:ring-primary-focus focus:border-0 active:text-primary-pressed active:border-primary-pressed disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-400",
        danger:
          "bg-danger text-white hover:bg-danger-hover focus:bg-danger focus:ring focus:ring-danger-focus active:bg-danger-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "danger-secondary":
          "bg-danger-bgcolor text-danger hover:text-danger-hover focus:text-danger focus:ring focus:ring-danger-focus active:text-danger-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "danger-outline":
          "bg-danger-bgcolor text-danger border border-danger-border hover:text-danger-hover hover:border-danger-hover focus:text-danger focus:ring focus:ring-danger-focus focus:border-0 active:text-danger-pressed active:border-danger-pressed disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-400",
        success:
          "bg-success text-white hover:bg-success-hover focus:bg-success focus:ring focus:ring-success-focus active:bg-success-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "success-secondary":
          "bg-success-bgcolor text-success hover:text-success-hover focus:text-success focus:ring focus:ring-success-focus active:text-success-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "success-outline":
          "bg-success-bgcolor text-success border border-success-border hover:text-success-hover hover:border-success-hover focus:text-success focus:ring focus:ring-success-focus focus:border-0 active:text-success-pressed active:border-success-pressed disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-400",
        warning:
          "bg-warning text-white hover:bg-warning-hover focus:bg-warning focus:ring focus:ring-warning-focus active:bg-warning-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "warning-secondary":
          "bg-warning-bgcolor text-warning hover:text-warning-hover focus:text-warning focus:ring focus:ring-warning-focus active:text-warning-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "warning-outline":
          "bg-warning-bgcolor text-warning border border-warning-border hover:text-warning-hover hover:border-warning-hover focus:text-warning focus:ring focus:ring-warning-focus focus:border-0 active:text-warning-pressed active:border-warning-pressed disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-400",
        info: "bg-info text-white hover:bg-info-hover focus:bg-info focus:ring focus:ring-info-focus active:bg-info-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "info-secondary":
          "bg-info-bgcolor text-info hover:text-info-hover focus:text-info focus:ring focus:ring-info-focus active:text-info-pressed disabled:bg-neutral-100 disabled:text-neutral-400",
        "info-outline":
          "bg-info-bgcolor text-info border border-info-border hover:text-info-hover hover:border-info-hover focus:text-info focus:ring focus:ring-info-focus focus:border-0 active:text-info-pressed active:border-info-pressed disabled:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-400",
        text: "text-primary hover:text-primary-hover focus:text-primary focus:ring focus:ring-primary-focus active:text-primary-pressed disabled:text-neutral-400",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:text-primary-hover focus:ring focus:ring-primary-border active:text-primary-pressed hover:underline",
      },
      size: {
        default: "px-4 py-2",
        sm: "rounded-md px-3 py-1",
        lg: "rounded-md px-8",
        icon: "h-10 w-10",
        inline: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
