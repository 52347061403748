import { Puzzles } from "@prisma/client";
import { StateCreator } from "zustand";

type PuzzleState = {
  puzzle: Puzzles | undefined;
  isFinished: boolean;
  result: "success" | "fail" | undefined;
  isScored: boolean;
  moves: any[];
};

type PuzzleStateSlice = {
  puzzleState: PuzzleState;
  setPuzzleState: (puzzleState: PuzzleState) => void;
};

const initialState = {
  puzzle: undefined,
  isFinished: false,
  result: undefined,
  isScored: false,
  moves: [],
};

const createPuzzleStateSlice: StateCreator<PuzzleStateSlice> = (set) => ({
  puzzleState: initialState,
  setPuzzleState: (data) =>
    set((state) => ({
      puzzleState: { ...state.puzzleState, ...data },
    })),
});

export default createPuzzleStateSlice;
export type { PuzzleState, PuzzleStateSlice };
