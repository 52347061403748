import { StateCreator } from "zustand";

type PersonalInfo = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  city: string;
  address: string;
  toc: boolean;
};

type PersonalInfoSlice = {
  personalInfo: PersonalInfo;
  setPersonalInfo: (data: PersonalInfo) => void;
};

const initialState = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  city: "",
  address: "",
  toc: false,
};

const createPersonalInfoSlice: StateCreator<PersonalInfoSlice> = (set) => ({
  personalInfo: initialState,
  setPersonalInfo: (data) =>
    set((state) => ({ personalInfo: { ...state.personalInfo, ...data } })),
});

export default createPersonalInfoSlice;
export type { PersonalInfo, PersonalInfoSlice };