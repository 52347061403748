import { StateCreator } from "zustand";

type OrderData = {
  userId: number;
  nickName: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  city: string;
  address: string;
  toc: boolean;
  Id: number; // rename required possinle ProductId
  Title: string;
  Price: number;
  Type: string;
  Amount: number;
  Duration: number;
  threeDsResponse: object;
  // threeDsForm: string;
  sessionToken: string;
  identityNumber: string;
  refCode: string;
  parentRefCode: string;
  customerRefCode: string;
  pricingRefCode: string;
  coupon: string;
  couponId: number;
  couponDiscountPerc: number;
  couponRefCode: string;
  total: number;
  subtotal: number;
  gifted_username: string;
  gift_message: string;
  gifted_user_id: string;
  gifted_usermail: string;
  cardname: string;
  expire: string;
  cardnumber: string;
  securitycode: string;
};

type OrderDataSlice = {
  orderData: OrderData;
  setOrderData: (orderData: OrderData) => void;
};

const initialState = {
  userId: 0,
  nickName: "",
  name: "",
  surname: "",
  email: "",
  phone: "",
  city: "",
  address: "",
  toc: false,
  Id: 0,
  Title: "",
  Price: 0,
  Type: "",
  Amount: 1,
  Duration: 0,
  threeDsResponse: {},
  sessionToken: "",
  identityNumber: "",
  //threeDsForm: "",
  refCode: "",
  parentRefCode: "",
  customerRefCode: "",
  pricingRefCode: "",
  coupon: "",
  couponId: 0,
  couponDiscountPerc: 0,
  couponRefCode: "",
  total: 0,
  subtotal: 0,
  gifted_username: "",
  gift_message: "",
  gifted_user_id: "",
  gifted_usermail: "",
  redirectUrl: "",
  cardname: "",
  expire: "",
  cardnumber: "",
  securitycode: "",
};

const createOrderDataSlice: StateCreator<OrderDataSlice> = (set) => ({
  orderData: initialState,
  setOrderData: (data) =>
    set((state) => ({ orderData: { ...state.orderData, ...data } })),
});

export default createOrderDataSlice;
export type { OrderData, OrderDataSlice };
