import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import * as jwt from "jsonwebtoken";
import { AttributesObject, PaginationMetaData } from "@/types";
import { ShopAttributes } from "@prisma/client";
import { convert } from "html-to-text";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function safeUrl(url?: string) {
  if (!url) {
    return null;
  }
  const regex = new RegExp("^https?://");
  if (regex.test(url)) {
    return encodeURI(url);
  }
  if (url[0] != "/") {
    return "/" + encodeURI(url);
  }
  return encodeURI(url);
}

export function isEmail(value?: string) {
  if (!value) {
    return false;
  }
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return value.match(validRegex);
}

export function getJWTToken(payload: any) {
  const nextAuthSecret = process.env.NEXTAUTH_SECRET || "nextauthsecret";
  return jwt.sign(payload, nextAuthSecret);
}

export function decodeJWTToken(token: string | null) {
  if (!token) return undefined;
  const nextAuthSecret = process.env.NEXTAUTH_SECRET || "nextauthsecret";
  try {
    return jwt.verify(token, nextAuthSecret);
  } catch (error) {
    return undefined;
  }
}

export function decimalPlaces(num: number | null, dp: number) {
  if (num === null) return "-";
  return Math.round(num * Math.pow(10, dp)) / Math.pow(10, dp);
}

export function getFormattedImageUrl(url: string | null | undefined) {
  if (!url) {
    return "";
  }
  const regex = new RegExp("^https?://");
  if (regex.test(url)) {
    return encodeURI(url);
  }
  if (url[0] != "/") {
    return "/" + encodeURI(url);
  }
  return encodeURI(url);
}

export function getPaginationMetaData(
  page: number,
  pageCount: number,
): PaginationMetaData {
  const hasPrevPage = page > 1;
  const hasNextPage = page < pageCount;
  const pages: number[] = [];

  const firstPage = 1;
  const lastPage = pageCount;
  const paginationDistance = 3;

  const meta = {
    pageCount: pageCount,
    hasPrevPage: hasPrevPage,
    hasNextPage: hasNextPage,
    prevPage: hasPrevPage ? page - 1 : undefined,
    nextPage: hasNextPage ? page + 1 : undefined,
    paginationPrevDots: false,
    paginationNextDots: false,
    pages: pages,
    currentPage: page,
  };

  if (pageCount <= 3 * paginationDistance + 1) {
    for (let i = 1; i <= pageCount; i++) {
      meta.pages.push(i);
    }

    return meta;
  }

  if (page - paginationDistance <= firstPage + 1) {
    meta.paginationNextDots = true;

    for (let i = 1; i < 2 * paginationDistance + 1; i++) {
      meta.pages.push(i);
    }

    return meta;
  }

  if (page + paginationDistance >= lastPage - 1) {
    meta.paginationPrevDots = true;
    for (let i = lastPage - 2 * paginationDistance; i <= lastPage; i++) {
      meta.pages.push(i);
    }

    return meta;
  }

  meta.paginationPrevDots = true;
  meta.paginationNextDots = true;
  for (let i = page - paginationDistance; i <= page + paginationDistance; i++) {
    meta.pages.push(i);
  }

  return meta;
}

export function updateQueryParams(
  uri: string,
  key: string,
  value: string | number | undefined,
) {
  const searchParams = new URLSearchParams(uri);
  if (!value) {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value.toString());
  }
  return searchParams.toString();
}

export function objectToSearchParams(params: {
  [key: string]: string | string[] | undefined;
}): URLSearchParams {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((val) => searchParams.append(key, val));
        } else {
          searchParams.set(key, value);
        }
      }
    }
  }
  return searchParams;
}

export function secondsToHMS(
  totalSeconds: number | null | undefined,
  showHours: boolean | null | undefined = false,
) {
  if (typeof showHours !== "boolean") {
    showHours = false;
  }
  if (!totalSeconds) {
    if (showHours) {
      return "--:--:--";
    }
    return "--:--";
  }

  if (showHours) {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds = totalSeconds % 3600;

    const minutes = Math.floor(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;

    const seconds = Math.floor(totalSeconds);

    return (
      (hours < 10 ? "0" : "") +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds
    );
  }

  const minutes = Math.floor(totalSeconds / 60);
  totalSeconds = totalSeconds % 60;

  const seconds = Math.floor(totalSeconds);

  return (
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds
  );
}

export function getShopAttributesObject(
  shopAttributes: ShopAttributes[] | undefined,
) {
  const attributes: AttributesObject = {
    backgroundUrl: null,
    storeNotice: null,
    overridedTrainerId: null,
    overridedCampaignText: null,
  };

  shopAttributes?.map((attributeObj: any) => {
    const name = attributeObj.AttributeName as keyof AttributesObject;
    const value = attributeObj.AttributeValue;

    if (name) {
      attributes[name] = value;
    }
  });

  return attributes;
}

export function getFormattedViewCounts(views: number | null | undefined) {
  if (!views || views === null) {
    return "0";
  }
  if (views >= 1000000) {
    return (views / 1000000).toFixed(1) + "Mn";
  } else if (views >= 1000) {
    return (views / 1000).toFixed(1) + "B";
  } else {
    return views.toString();
  }
}

export function getExcerpt(htmlString: string) {
  return (
    convert(htmlString, {
      wordwrap: 40,
      selectors: [
        { selector: "img", format: "skip" },
        { selector: "figure", format: "skip" },
      ],
    })
      .split(" ")
      .slice(0, 25)
      .join(" ") + "..."
  ).trim();
}

export const playerTextColors: Record<string, string> = {
  GM: "text-[#ba1717]",
  WGM: "text-[#ba1717]",
  IM: "text-[#3818d9]",
  WIM: "text-[#3818d9]",
  FM: "text-[#e9c200]",
  WFM: "text-[#e9c200]",
  CM: "text-[#cd6b2e]",
  WCM: "text-[#cd6b2e]",
};

export const playerBadgeColors: Record<string, string> = {
  GM: "bg-[#ba1717]",
  WGM: "bg-[#ba1717]",
  IM: "bg-[#3818d9]",
  WIM: "bg-[#3818d9]",
  FM: "bg-[#e9c200]",
  WFM: "bg-[#e9c200]",
  CM: "bg-[#cd6b2e]",
  WCM: "bg-[#cd6b2e]",
};

export const playerBorderColors: Record<string, string> = {
  GM: "border-[#ba1717]",
  WGM: "border-[#ba1717]",
  IM: "border-[#3818d9]",
  WIM: "border-[#3818d9]",
  FM: "border-[#e9c200]",
  WFM: "border-[#e9c200]",
  CM: "border-[#cd6b2e]",
  WCM: "border-[#cd6b2e]",
};
